<template>
  <div class="wrapper">
    <div>
      <router-link to="/">
        <img
          class="ml-2 mt-2"
          style="width: 30px"
          src="/img/TopNav/cancel.png"
        />
      </router-link>
    </div>

    <div style="text-align: center; margin: 20px">
      <img src="/img/chopbetbanner.png" style="width: 100%; max-width: 100%" />
    </div>

    <section class="register-form-wrapper p-4" v-show="action_login">
      <h3 class="text-center">
        <span class="text-dark">Log into your account</span>
      </h3>
      <div class="text-center text-dark mb-3">
        Enter your details to continue gaming
      </div>
      <div class="form-wrapper mb-3">
        <form id="login-form">
          <div class="mb-0">
            <input
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
              "
              type="tel"
              class="form-control"
              id="phoneInput"
              placeholder="Phone number"
              aria-label="Enter Phone number"
              name="mobile"
              v-model="msisdn"
            />
          </div>
          <small v-if="phoneInvalid" class="text-danger text-center"
            >Invalid phone number</small
          >
          <div class="mb-3"></div>
          <div class="mb-0">
            <div
              class="input-group"
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
              "
            >
              <input
                :type="type"
                class="form-control"
                id="inputPass"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                "
                name="password"
                placeholder="Enter Password"
                aria-label="Enter Password"
                v-model="password"
              />
              <div
                class="input-group-prepend"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                "
              >
                <span
                  style="background-color: var(--white)"
                  class="btn btn-sm mt-1"
                  type="button"
                  ><img
                    @click="showPassword"
                    style="width: 20px"
                    :src="btnText"
                /></span>
              </div>
            </div>
          </div>
          <small class="text-blue mb-3 d-none">{{
            $t("enter4DigitCode")
          }}</small>
          <input type="hidden" name="utm_source" value="" />
          <input type="hidden" name="utm_medium" value="" />
          <input type="hidden" name="utm_campaign" value="" />
          <input type="hidden" name="referrer" value="" />
        </form>
      </div>

      <p class="text-dark mb-5">
        <strong style="float: right">
          <a @click="setGetResetCode">FORGOT PASSWORD?</a>
        </strong>
      </p>

      <div class="button-wrapper text-center mb-3">
        <a
          v-if="!spinner"
          @click="handleLoginClick"
          class="join-button py-2 form-control text-center special-font"
          style="text-transform: uppercase"
        >
          {{ $t("login") }}</a
        >
        <a v-if="spinner" class="join-button py-2 form-control">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
            <br />
          </div>
        </a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3">
        <div class="text-center text-dark mb-3">
          <div class="text-center text-dark mb-3">
            {{ $t("dontHaveAccount") }}
            <a @click="setSignup">
              <span
                class="special-font"
                style="text-decoration: underline; color: var(--red)"
              >
                <strong>{{ $t("joinNow") }}</strong></span
              ></a
            >
            <br />
          </div>
        </div>
      </div>
    </section>

    <section class="register-form-wrapper p-4" v-show="action_signup">
      <h3 class="text-dark text-center">{{ $t("signUp") }}</h3>

      <div class="text-center text-dark mb-3">
        Let’s get to know you before joining the action
      </div>
      <div class="form-wrapper mb-1">
        <form>
          <div class="mb-3">
            <input
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
              "
              type="tel"
              class="form-control"
              placeholder="Phone number"
              aria-label="Enter Phone Number"
              name="mobile"
              id="msisdn"
              v-model="msisdn"
            />
          </div>
          <div class="mb-3">
            <div
              class="input-group"
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
              "
            >
              <input
                :type="type"
                class="form-control"
                id="inputPass"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                "
                name="password"
                placeholder="Enter Password"
                aria-label="Enter Password"
                v-model="password"
              />
              <div
                class="input-group-prepend"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                "
              >
                <span
                  style="background-color: var(--white)"
                  class="btn btn-sm mt-1"
                  type="button"
                  ><img
                    @click="showPassword"
                    style="width: 20px"
                    :src="btnText"
                /></span>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div
              class="input-group"
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
              "
            >
              <input
                :type="type"
                class="form-control"
                id="signupPassword1"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                "
                name="password_2"
                placeholder="Confirm Password"
                aria-label="Confirm Password"
                v-model="password1"
              />
              <div
                class="input-group-prepend"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                "
              >
                <span
                  style="background-color: var(--white)"
                  class="btn btn-sm mt-1"
                  type="button"
                  ><img
                    @click="showPassword"
                    style="width: 20px"
                    :src="btnText"
                /></span>
              </div>
            </div>
          </div>

          <div class="disclaimer text-center text-dark">
            At least 6 characters, containing <strong> a letter</strong> and
            <strong> a number</strong>.
          </div>

          <h6 class="text-dark">Optional Details</h6>

          <div class="mb-3">
            <input
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
              "
              type="email"
              class="form-control"
              placeholder="Email Address"
              aria-label="Email Address"
              name="email"
              id="email"
              v-model="email"
            />
          </div>

          <div class="mb-3">
            <input
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
              "
              type="text"
              class="form-control"
              placeholder="Promo code"
              aria-label="Promo code"
              name="email"
              id="promo"
              v-model="promo"
            />
          </div>

          <div class="form-check mb-3">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked1"
              required
            />
            <label class="form-check-label text-dark" for="flexCheckChecked1">
              I agree to be of legal betting age in the state in which I reside
              in.
            </label>
          </div>

          <div class="form-check mb-3">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked2"
              required
            />
            <label class="form-check-label text-dark" for="flexCheckChecked2">
              I have read and agree to Choplife’s
              <span style="color: var(--red)">
                <router-link to="/terms">Terms & Conditions</router-link> </span
              >.
            </label>
          </div>
        </form>
      </div>

      <div class="button-wrapper text-center mb-3">
        <a
          v-if="!spinner"
          @click="handleSignupClick"
          class="join-button py-2 form-control text-center special-font special-font"
          >CONTINUE</a
        >
        <a v-if="spinner" class="join-button py-2 form-control">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
            <br />
          </div>
        </a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3">
        <div class="text-center text-dark mb-3">
          Already have an account?
          <a @click="setLogin">
            <span
              class="special-font"
              style="text-decoration: underline; color: var(--red)"
            >
              <strong>Login</strong>
            </span></a
          >
          <br />
        </div>
      </div>
    </section>

    <section class="register-form-wrapper p-4" v-show="action_verify_password">
      <h3 class="text-dark text-center">
        Registration <span class="text-dark">Code</span>
      </h3>
      <div class="text-center text-dark mb-3">
        Check your phone for an SMS verification code
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <div class="mb-0">
            <input
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
                text-align: center;
              "
              type="tel"
              class="form-control"
              placeholder="Enter code"
              id="code"
              aria-label="Enter Code"
              v-model="code"
            />
          </div>
          <small class="text-blue mb-3"
            >Enter the 4 digit code sent to your phone</small
          >
        </form>
      </div>

      <div class="disclaimer text-center text-dark">
        By activating your Choplife account, you confirm that you are 18 years
        old or over and agree with the
        <a class="text-dark">Terms, conditions and policies</a> of Choplife
      </div>

      <div class="button-wrapper text-center mb-3">
        <a
          @click="handleVerifyClick"
          class="join-button py-2 form-control special-font"
          >Activate Account</a
        >
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3">
        <div class="text-center text-dark mb-3">
          Didn’t get an SMS code?<br />
          Let’s try again in<br />
          (15 seconds)
        </div>
        <a href="#n" class="login-button py-2 form-control">Resend</a>
      </div>
    </section>

    <section class="register-form-wrapper p-4" v-show="action_reset_password">
      <h3 class="text-dark text-center">Forgot Password?</h3>
      <div class="text-center text-dark mb-3">
        Enter your registered phone number. We’ll send you a code to reset your
        password
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <div class="mb-3">
            <input
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
              "
              type="tel"
              class="form-control"
              id="msisdn1"
              placeholder="Phone number"
              aria-label="Amount (to the nearest dollar)"
              v-model="msisdn"
            />
          </div>
        </form>
      </div>

      <div class="button-wrapper text-center mb-3">
        <a
          v-if="!spinner"
          @click="handleResetClick"
          class="join-button py-2 form-control text-center special-font"
          >CONTINUE</a
        >
        <a v-if="spinner" class="join-button py-2 form-control">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
            <br />
          </div>
        </a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-1">
        <div class="text-center text-dark mb-3">
          Already have an account?
          <a @click="setLogin">
            <span
              class="special-font"
              style="text-decoration: underline; color: var(--red)"
            >
              <strong>Login</strong>
            </span></a
          ><br />
        </div>
      </div>
    </section>

    <section class="register-form-wrapper p-4" v-show="action_change_password">
      <h3 class="text-dark text-center">Enter OTP</h3>
      <div class="text-center text-dark mb-3">
        We just sent a code to your phone number
        <br />
        <strong> {{ msisdn }} </strong>
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <div class="mb-3">
            <input
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
                text-align: center;
              "
              type="hidden"
              class="form-control"
              id="phoneInput"
              placeholder="Phone number 07 or 01"
              aria-label="Enter Phone number"
              name="mobile"
              v-model="msisdn"
            />
          </div>
          <div
            class="verification-code-wrapper mb-3"
            style="display: flex; justify-content: space-between; gap: 10px"
          >
            <input
              v-model="code1"
              @input="moveToNext($event, 'code2')"
              maxlength="1"
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
                text-align: center;
              "
              type="tel"
              placeholder="•"
              aria-label="Verification Code 1"
              id="code1"
            />
            <input
              v-model="code2"
              @input="moveToNext($event, 'code3')"
              maxlength="1"
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
                text-align: center;
              "
              type="tel"
              placeholder="•"
              aria-label="Verification Code 2"
              id="code2"
            />
            <input
              v-model="code3"
              @input="moveToNext($event, 'code4')"
              maxlength="1"
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
                text-align: center;
              "
              type="tel"
              placeholder="•"
              aria-label="Verification Code 3"
              id="code3"
            />
            <input
              v-model="code4"
              maxlength="1"
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
                text-align: center;
              "
              type="tel"
              placeholder="•"
              aria-label="Verification Code 4"
              id="code4"
            />
          </div>

          <div class="mb-3">
            <div
              class="input-group"
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
                text-align: center;
              "
            >
              <input
                :type="type"
                class="form-control"
                id="signupPassword3"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
                name="password"
                placeholder="New Password"
                aria-label="New Password"
                v-model="password"
              />
              <div
                class="input-group-prepend"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
              >
                <span
                  style="background-color: var(--white)"
                  class="btn btn-sm mt-1"
                  type="button"
                  ><img
                    @click="showPassword"
                    style="width: 20px"
                    :src="btnText"
                /></span>
              </div>
            </div>
          </div>
          <div class="mb-0">
            <div
              class="input-group"
              style="
                border: 1px black solid;
                border-radius: 5px;
                background-color: white;
                color: black;
                width: 100%;
                height: 50px;
                text-align: center;
              "
            >
              <input
                :type="type"
                class="form-control"
                id="signupPassword1"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
                name="password_2"
                placeholder="Confirm New Password"
                aria-label="Confirm New Password"
                v-model="password1"
              />
              <div
                class="input-group-prepend"
                style="
                  border-radius: 5px;
                  background-color: white;
                  color: black;
                  text-align: center;
                "
              >
                <span
                  style="background-color: var(--white)"
                  class="btn btn-sm mt-1"
                  type="button"
                  ><img
                    @click="showPassword"
                    style="width: 20px"
                    :src="btnText"
                /></span>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="button-wrapper text-center mb-3">
        <a
          v-if="!spinner"
          @click="handleChangePasswordClick"
          class="join-button py-2 form-control text-center special-font"
          >Change Password</a
        >
        <a v-if="spinner" class="join-button py-2 form-control">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
            <br />
          </div>
        </a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-1">
        <div class="text-center text-dark mb-3">
          Already have an account?
          <a @click="setLogin">
            <span
              class="special-font"
              style="text-decoration: underline; color: var(--red)"
            >
              <strong>LOGIN</strong>
            </span></a
          ><br />
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
label {
  font-weight: 600;
}

.input-signup,
.input-login {
  border: 1px solid #ccc;
  border-radius: 0.5em;
  height: 3em;
}
</style>

<script>
import axios from "@/services/identity";
import { pushToDataLayer } from "@/utils/gtm";
import { mapState } from "vuex";

export default {
  name: "Login",
  components: {},
  comments: {},
  data: function () {
    return {
      first_name: "",
      last_name: "",
      msisdn: "",
      password: "",
      password1: "",
      error: [],
      warning: [],
      success: [],
      loading: "",
      spinner: false,
      action_login: true,
      action_signup: false,
      action_verify_password: false,
      action_reset_password: false,
      action_change_password: false,
      code: "",
      placeBet: 0,
      type: "password",
      type2: "password",
      type3: "password",
      btnText: "/img/icons/eye.svg",
      btnText2: "/img/other/eye.svg",
      btnText3: "/img/other/eye.svg",
      showSuccessModal: false,
      code1: "",
      code2: "",
      code3: "",
      code4: "",
    };
  },
  computed: {
    phoneInvalid: function () {
      if (this.msisdn.length < 10 && this.msisdn.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    ...mapState(["referralCode"]),
  },
  methods: {
    handleLoginClick(event) {
      this.login(event);
    },
    trackLoginButtonClick(event) {
      pushToDataLayer("gtm.login", {
        category: "Button",
        action: "Click",
        label: "Login",
        element: event.target,
      });
    },
    login: function (event) {
      this.reset();
      this.removeAuth();

      this.msisdn = this.msisdn.replace(/\D/g, "");

      if (this.msisdn.length < 9 || parseInt(this.msisdn) === 0) {
        this.$toast.open({
          message: "Please enter a valid mobile number",
          type: "error",
          position: "top",
        });
        return;
      }

      if (this.password.length < 4) {
        this.$toast.open({
          message: "Please enter a password of more than 4 characters",
          type: "error",
          position: "top",
        });
        return;
      }

      this.loading = "loading";
      this.spinner = true;

      var vm = this;

      var path = "/login";

      var currentDate = new Date();
      var login_tag = parseInt(
        currentDate.getTime() +
          "" +
          Math.floor(Math.random() * (9 * Math.pow(10, 2))) +
          Math.pow(10, 2)
      );
      this.setValue("login_tag", login_tag);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");

      console.log("Login method triggered");
      console.log("Document Referrer:", document.referrer);
      console.log("Payload:", {
        msisdn: parseInt(this.msisdn),
        password: this.password,
        login_tag: login_tag,
        source: utm_source,
        medium: utm_medium,
        campaign: utm_campaign,
        referrer: document.referrer,
      });
      axios
        .post(
          path,
          JSON.stringify({
            msisdn: parseInt(this.msisdn),
            password: this.password,
            login_tag: login_tag,
            source: utm_source,
            medium: utm_medium,
            campaign: utm_campaign,
            referrer: document.referrer,
          })
        )
        .then((res) => {
          vm.loading = "";
          vm.spinner = false;

          var profile = res.data;
          var status = res.status;

          if (parseInt(status) === 201 || parseInt(status) === 204) {
            vm.setVerifyAccount();
            return;
          }

          vm.setProfile(profile);
          var auth = profile.auth;
          vm.setAuth(auth);

          vm.trackLoginButtonClick(event);

          this.$toast.open({
            message: "Login successful!",
            type: "success",
            position: "top",
          });

          vm.EventBus.$emit("init:mqtt");

          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
        })
        .catch((err) => {
          vm.loading = "";
          vm.spinner = false;

          if (err.response) {
            this.$toast.open({
              message: "Login failed!",
              type: "error",
              position: "top",
            });
          } else if (err.request) {
            this.$toast.open({
              message: `${this.$t("checkYourNetwork")}`,
              type: "error",
              position: "top",
            });
            console.log(JSON.stringify(err.request));
          } else {
            this.$toast.open({
              message: `${this.$t("checkYourNetwork")}`,
              type: "error",
              position: "top",
            });
            console.log(JSON.stringify(err));
          }
        });
    },

    handleBlur: function () {
      if (this.msisdn.length < 9 && this.msisdn.length > 0) {
        this.$toast.open({
          message: "Please enter a valid mobile number",
          type: "error",
          position: "top",
        });
        document.getElementById("phoneInput").classList.add("is-invalid");
      } else {
        document.getElementById("phoneInput").classList.remove("is-invalid");
      }
    },

    getBrowserInfo() {
      return navigator.userAgent || "unknown";
    },

    getDeviceInfo() {
      return `${navigator.platform} ${navigator.userAgent}`;
    },

    getEngineInfo() {
      return "JavaScript";
    },

    getVersionInfo() {
      return navigator.appVersion || "unknown";
    },

    handleSignupClick(event) {
      this.signup(event);
    },
    trackSignupButtonClick(event) {
      pushToDataLayer("gtm.register", {
        category: "Button",
        action: "Click",
        label: "Register",
        element: event.target,
      });
    },
    signup: function (event) {
      this.reset();

      this.msisdn = this.msisdn.replace(/\D/g, "");

      if (this.msisdn.length < 9 || parseInt(this.msisdn) === 0) {
        this.$toast.open({
          message: "Please enter a valid mobile number",
          type: "error",
          position: "top",
        });
        return;
      }

      if (this.password.length < 4) {
        this.$toast.open({
          message: "Please enter a password of more than 4 characters",
          type: "error",
          position: "top",
        });
        return;
      }

      if (this.password !== this.password1) {
        this.$toast.open({
          message: "Your passwords do not match",
          type: "error",
          position: "top",
        });
        return;
      }

      this.loading = "loading";
      this.spinner = true;

      var vm = this;
      var path = "/signup";

      var browser = this.getBrowserInfo();
      var deviceInfo = this.getDeviceInfo();
      var engine = this.getEngineInfo();
      var versionInfo = this.getVersionInfo();

      var channelId = parseInt(this.getValue("channel_id")) || 0;

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var btag = this.getValue("btag");

      console.log("Register method triggered");
      console.log("Document Referrer:", document.referrer);

      console.log("Register method triggered");
      console.log("Document Referrer:", document.referrer);
      console.log("Referral Code:", this.referralCode);

      axios
        .post(
          path,
          JSON.stringify({
            browser: browser,
            channel_id: channelId,
            device_info: deviceInfo,
            engine: engine,
            version_info: versionInfo,
            msisdn: parseInt(this.msisdn),
            password: this.password,
            source: utm_source,
            medium: utm_medium,
            campaign: utm_campaign,
            referrer: document.referrer,
            referral_code: this.referralCode,
            btag: btag,
          })
        )
        .then((res) => {
          vm.spinner = false;
          vm.loading = "";

          console.log("Response:", JSON.stringify(res, undefined, 2));

          var status = res.status;

          if (parseInt(status) === 202) {
            vm.setLogin();
            return;
          }

          if (parseInt(status) === 201 || parseInt(status) === 204) {
            vm.setVerifyAccount();
            return;
          }

          vm.trackSignupButtonClick(event);

          this.$toast.open({
            message: "Signup successful",
            type: "success",
            position: "top",
          });
          vm.setVerifyAccount();
        })
        .catch((err) => {
          vm.spinner = false;
          vm.loading = "";

          if (err.response) {
            this.$toast.open({
              message: err.response.data.error_message,
              type: "error",
              position: "top",
            });
          } else if (err.request) {
            this.$toast.open({
              message: `${this.$t("checkYourNetwork")}`,
              type: "error",
              position: "top",
            });
            console.log(JSON.stringify(err.request));
          } else {
            this.$toast.open({
              message: `${this.$t("checkYourNetwork")}`,
              type: "error",
              position: "top",
            });
            console.log(JSON.stringify(err));
          }
        });
    },

    handleResetClick(event) {
      this.getResetCode(event);
    },
    trackResetButtonClick(event) {
      pushToDataLayer("gtm.reset", {
        category: "Button",
        action: "Click",
        label: "Reset",
        element: event.target,
      });
    },
    getResetCode: function (event) {
      this.reset();

      if (this.msisdn.length < 9) {
        this.$toast.open({
          message: "Please enter a valid mobile number",
          type: "error",
          position: "top",
        });
        return;
      }

      this.spinner = true;
      this.loading = "loading";

      var vm = this;
      var path = process.env.VUE_APP_URL_PASSWORD_RESET;

      var payload = {
        msisdn: parseInt(this.msisdn),
      };

      console.log("Request Payload:", payload);

      axios
        .patch(path, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          vm.spinner = false;
          vm.loading = "";

          console.log(JSON.stringify(res));

          vm.trackResetButtonClick(event);

          this.$toast.open({
            message: "Password reset code has been sent to your phone",
            type: "success",
            position: "top",
          });

          vm.setChangePassword();
        })
        .catch((err) => {
          vm.spinner = false;
          console.log(JSON.stringify(err));

          if (err.response) {
            this.$toast.open({
              message: err.response.data.error_message,
              type: "error",
              position: "top",
            });
            console.log(JSON.stringify(err.response.data.message));
          } else if (err.request) {
            this.$toast.open({
              message: `${this.$t("checkYourNetwork")}`,
              type: "error",
              position: "top",
            });
            console.log(JSON.stringify(err.request));
          } else {
            this.$toast.open({
              message: `${this.$t("checkYourNetwork")}`,
              type: "error",
              position: "top",
            });
            console.log(JSON.stringify(err));
          }
        });
    },

    handleChangePasswordClick(event) {
      this.changePassword(event);
    },
    trackChangePasswordButtonClick(event) {
      pushToDataLayer("gtm.changePassword", {
        category: "Button",
        action: "Click",
        label: "Change Password",
        element: event.target,
      });
    },
    moveToNext(event, nextInputId) {
      if (event.target.value.length === 1) {
        // Move focus to the next input box when one character is entered
        this.$refs[nextInputId].focus();
      }
    },
    changePassword: function (event) {
      this.reset();

      if (this.password.length < 4) {
        this.$toast.open({
          message: "Please enter a password of more than 4 characters",
          type: "error",
          position: "top",
        });
        return;
      }

      if (this.password !== this.password1) {
        this.$toast.open({
          message: "Your passwords do not match",
          type: "error",
          position: "top",
        });
        return;
      }

      // Combine the four verification code inputs into a single string
      const fullCode = this.code1 + this.code2 + this.code3 + this.code4;

      if (fullCode.length !== 4) {
        this.$toast.open({
          message: "Please enter a valid 4-digit verification code",
          type: "error",
          position: "top",
        });
        return;
      }

      this.spinner = true;
      this.loading = "loading";

      var vm = this;
      var path = process.env.VUE_APP_URL_PASSWORD_CHANGE;

      var payload = {
        msisdn: parseInt(this.msisdn),
        password: this.password,
        code: parseInt(fullCode), // Use the combined code here
      };

      console.log("Request Payload:", payload);

      axios
        .patch(path, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(JSON.stringify(res));

          vm.spinner = false;
          vm.loading = "";

          vm.trackChangePasswordButtonClick(event);

          this.$toast.open({
            message: "Your password has been changed successfully",
            type: "success",
            position: "top",
          });

          vm.setLogin();
        })
        .catch((err) => {
          vm.spinner = false;
          vm.loading = "";

          if (err.response) {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              position: "top",
            });
            console.log(JSON.stringify(err.response.data.message));
          } else if (err.request) {
            this.$toast.open({
              message: `${this.$t("checkYourNetwork")}`,
              type: "error",
              position: "top",
            });
            console.log(JSON.stringify(err.request));
          } else {
            this.$toast.open({
              message: `${this.$t("checkYourNetwork")}`,
              type: "error",
              position: "top",
            });
            console.log(JSON.stringify(err));
          }
        });
    },

    handleVerifyClick(event) {
      this.verifyAccount(event);
    },
    trackVerifyButtonClick(event) {
      pushToDataLayer("gtm.verify", {
        category: "Button",
        action: "Click",
        label: "Verify",
        element: event.target,
      });
    },
    verifyAccount: function (event) {
      this.reset();

      if (this.code.length < 4) {
        this.$toast.open({
          message: "Please enter a valid verification code",
          type: "error",
          position: "top",
        });
        return;
      }

      this.spinner = true;
      this.loading = "loading";

      var vm = this;
      var path = process.env.VUE_APP_URL_VERIFY;
      var currentDate = new Date();
      var login_tag = parseInt(
        currentDate.getTime() +
          "" +
          Math.floor(Math.random() * (9 * Math.pow(10, 2))) +
          Math.pow(10, 2)
      );
      this.setValue("login_tag", login_tag);

      var utm_source = this.getValue("utm_source") || "";
      var utm_medium = this.getValue("utm_medium") || "";
      var utm_campaign = this.getValue("utm_campaign") || "";

      var payload = {
        msisdn: parseInt(this.msisdn),
        code: parseInt(this.code),
        login_tag: login_tag,
        source: utm_source,
        medium: utm_medium,
        campaign: utm_campaign,
        referrer: document.referrer,
      };

      console.log("Request Payload:", payload);

      axios
        .post(path, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          vm.spinner = false;
          vm.loading = "";
          var profile = res.data;
          var status = res.status;

          console.log("Response Status:", status);
          console.log("Response Data:", profile);

          vm.trackVerifyButtonClick(event);
          vm.setProfile(profile);
          var auth = profile.auth;
          vm.setAuth(auth);

          this.$toast.open({
            message:
              "Congratulations! Your account has been verified successfully",
            type: "success",
            position: "top",
          });

          vm.EventBus.$emit("init:mqtt");

          if (parseInt(vm.placeBet) === 1) {
            vm.setValue("placeBet", 0);
            vm.EventBus.$emit("event:betslip:show");
          } else {
            setTimeout(() => {
              window.location.href = "/";
            }, 2000);
          }
        })
        .catch((err) => {
          vm.spinner = false;
          vm.loading = "";
          console.log("Error:", err);

          if (err.response) {
            console.log("Error Response Data:", err.response.data);
            console.log("Error Response Status:", err.response.status);
            console.log("Error Response Headers:", err.response.headers);

            this.$toast.open({
              message: err.response.data.error_message,
              type: "error",
              position: "top",
            });
          } else if (err.request) {
            this.$toast.open({
              message: `${this.$t("checkYourNetwork")}`,
              type: "error",
              position: "top",
            });
            console.log("Error Request:", err.request);
          } else {
            this.$toast.open({
              message: `${this.$t("checkYourNetwork")}`,
              type: "error",
              position: "top",
            });
            console.log("Error Message:", err.message);
          }
        });
    },
    setSignup: function () {
      this.action_login = false;
      this.action_signup = true;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = false;
    },
    setLogin: function () {
      this.action_login = true;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = false;
    },
    setGetResetCode: function () {
      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = true;
      this.action_verify_password = false;
    },
    setChangePassword: function () {
      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = true;
      this.action_reset_password = false;
      this.action_verify_password = false;
    },
    setVerifyAccount: function () {
      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = true;
    },
    showPassword: function () {
      if (document.getElementById("inputPass").type == "password") {
        document.getElementById("inputPass").type = "text";
        document.getElementById("inputPass").style.color = "white";
      } else {
        document.getElementById("inputPass").type = "password";
        document.getElementById("inputPass").style.color =
          "rgba(255,255,255,.75)";
      }
      // if(document.getElementById('signupPassword1').type == "password"){
      //   document.getElementById('signupPassword1').type = "text"
      // }else{
      //   document.getElementById('signupPassword1').type = "password"
      // }
      if (this.type === "password") {
        this.type = "text";
        this.btnText = "/img/icons/eye-slash.svg";
      } else {
        this.type = "password";
        this.btnText = "/img/icons/eye.svg";
      }
    },

    showPasswordReset: function () {
      if (document.getElementById("signupPassword3").type == "password") {
        document.getElementById("signupPassword3").type = "text";
        document.getElementById("signupPassword3").style.color = "white";
      } else {
        document.getElementById("signupPassword3").type = "password";
        document.getElementById("signupPassword3").style.color =
          "rgba(255,255,255,.75)";
      }
      if (this.type2 === "password") {
        this.type2 = "text";
        this.btnText2 = "/img/other/eyeslash.svg";
      } else {
        this.type2 = "password";
        this.btnText2 = "/img/other/eye.svg";
      }
    },
    showPasswordReset2: function () {
      if (document.getElementById("signupPassword4").type == "password") {
        document.getElementById("signupPassword4").type = "text";
        document.getElementById("signupPassword4").style.color = "white";
      } else {
        document.getElementById("signupPassword4").type = "password";
        document.getElementById("signupPassword4").style.color =
          "rgba(255,255,255,.75)";
      }
      if (this.type3 === "password") {
        this.type3 = "text";
        this.btnText3 = "/img/other/eyeslash.svg";
      } else {
        this.type3 = "password";
        this.btnText3 = "/img/other/eye.svg";
      }
    },
  },
  mounted: function () {
    this.$store.dispatch("setCurrentPage", "login");
    this.placeBet = this.getValue("placeBet");
    var p = this.getProfile();
    if (!p) {
      console.log("");
    } else {
      this.$router.push({ name: "home", params: {} });
      return;
    }
  },
};
</script>
